<template>
  <ASocialMediaAuthButton
    class="fa-github"
    :icon="['fab', 'github']"
    size="lg"
    name="Github"
    service="github"
  />
</template>

<script>
import ASocialMediaAuthButton from '@/components/accountmanagement/a-socialmedia-auth-button'
export default {
  name: 'github-auth-button',
  components: {
    ASocialMediaAuthButton,
  },
}
</script>

<style scoped>
.fa-github {
  background: #24292e;
  color: white;
}
</style>
