<template>
  <b-card no-body class="pq-jobs overflow-hidden">
    <b-tabs pills card>
      <b-tab title="Recent Projects" active>
        <template v-for="item in userProjects.slice(0, 5)">
          <ProjectItem
            v-if="item"
            :key="item.id"
            :project-id="item.id"
            :project-name="item.name"
            :updated-at="$utilFunc.convertTime(item.updatedAt)"
          />
        </template>
      </b-tab>
      <b-tab title="Recent jobs">
        <template v-for="item in userJobs.slice(0, 5)">
          <JobItem
            v-if="item"
            :key="item.id"
            :job-id="item.id"
            :project-id="item.ProjectId"
            :job-status="item.status"
            :updated-at="$utilFunc.convertTime(item.updatedAt)"
          />
        </template>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import JobItem from '@/components/project/job-item'
import ProjectItem from '@/components/project/project-item'
import { GET_USER_PROJECTS, USER_JOBS } from '@/store/actions.type'
import { mapGetters } from 'vuex'

export default {
  name: 'project-panel-dashboard',
  components: {
    JobItem,
    ProjectItem,
  },
  data() {
    return {
      userProjects: [],
      userJobs: [],
    }
  },
  mounted() {
    this.$store.dispatch(GET_USER_PROJECTS)
    this.$store.dispatch(USER_JOBS)
  },
  computed: {
    ...mapGetters(['getUserProjects', 'getUserJobs']),
  },
  watch: {
    getUserProjects() {
      this.userProjects = this.getUserProjects
    },
    getUserJobs() {
      this.userJobs = this.getUserJobs
    },
  },
}
</script>

<style>
.pq-jobs {
  min-height: 25rem;
  border-radius: 1rem;
  background-color: var(--pq-blue-shade2);
  box-shadow: 0 0 15px 5px rgb(0, 0, 0, 0.07);
}

.tab-pane {
  padding: 0;
}

.pq-jobs .card-header {
  padding: 0.5rem 1rem;
}

.pq-jobs .nav-link {
  color: var(--pq-font-color-shade3) !important;
}
.pq-jobs .active {
  color: var(--pq-font-color-shade1) !important;
  font-weight: 500;
  background-color: transparent !important;
}

.pq-jobs header h5 {
  font-size: 1rem;
  color: var(--pq-font-color-shade3);
  margin: 0;
}

.pq-jobs header span {
  font-size: 0.85rem;
}

.pq-jobs .project-item:last-of-type {
  border-bottom: none;
}
</style>
