<template>
  <button
    v-if="this.getProject"
    class="job-item d-flex flex-column"
    @click="$router.push({ name: 'dashboard-jobDetails', params: { jobId } })"
  >
    <div class="job-item-status d-flex flex-row mb-2">
      <span class="project-name">{{ this.getProject.name }}</span>
      <img
        :src="require(`@/assets/images/icons/${jobStatus}.svg`)"
        :alt="`${jobStatus}`"
      />
    </div>
    <div class="job-item-updated-at">
      <img
        class="user-profile-picture mr-2"
        :src="currentUser.avatar"
        alt="Profile picture"
      />
      <span>Submitted {{ updatedAt }}</span>
    </div>
  </button>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'job-item',
  props: ['jobId', 'ProjectId', 'jobStatus', 'updatedAt'],
  computed: {
    ...mapGetters(['currentUser', 'getUserProjects']),
    getProject() {
      return this.getUserProjects[
        this.getUserProjects.findIndex((x) => x.id === this.ProjectId)
      ]
    },
  },
}
</script>

<style scoped>
.job-item {
  color: var(--pq-font-color-shade2);
  width: 100%;
  padding: 1rem 1rem;
  border: none;
  border-bottom: 1px solid #293042;
  background-color: transparent;
}

.job-item:hover {
  background-color: var(--pq-blue-shade3);
}

.job-item-status {
  width: 100%;
  justify-content: space-between;
}

.job-item-status img {
  width: 1.15rem;
}

.project-name {
  color: var(--pq-font-color-shade1);
  font-weight: 400;
}

.job-item-updated-at {
  font-size: 0.85rem;
  color: var(--pq-font-color-shade3);
}

.user-profile-picture {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
}
</style>
