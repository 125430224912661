<template>
  <button
    class="project-item d-flex flex-row align-items-center"
    v-on:click="openProject()"
  >
    <span
      class="project-item-header d-flex flex-row align-items-center justify-content-start"
    >
      <span class="mr-3">{{ projectIndex }}.</span>
      <span class="project-item-header-name">{{ projectName }}</span>
    </span>
    <span class="user">
      <p>{{ userName }}</p>
      <img :src="userAvatar" alt="User avatar" />
    </span>
  </button>
</template>

<script>
export default {
  name: 'public-project-item',
  props: ['projectId', 'projectIndex', 'projectName', 'userName', 'userAvatar'],
  methods: {
    openProject() {
      this.$router.push({
        name: 'published',
        params: { projectId: this.projectId },
      })
    },
  },
}
</script>

<style scoped>
.project-item {
  color: var(--pq-font-color-shade2);
  width: 100%;
  padding: 1rem 1rem;
  border: none;
  border-bottom: 1px solid var(--pq-color-border);
  background-color: transparent;
  justify-content: space-between;
}

.project-item:hover {
  background-color: var(--pq-blue-shade3);
}

.project-item-header {
  color: var(--pq-font-color-shade1);
}
.project-item .user {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.project-item .user > img {
  height: 1.5rem;
  border-radius: 1.5rem;
  margin-left: 1rem;
}
.project-item .user p {
  color: var(--pq-font-color-shade2);
  font-size: 0.85rem;
  margin: 0;
}
</style>
