<template>
  <div class="dashboard-page">
    <div class="circ"></div>
    <section class="row welcome-section align-items-start">
      <header class="col-sm-12 col-md-6 col-xl-8 mb-5 mt-5">
        <div v-if="!this.isAuthenticated">
          <h1 class="mb-2">Welcome to Piquasso,</h1>
          <p class="mb-5 subtitle" style="font-size: 1.2rem">
            a high-performance, open-source photonic quantum computer simulator
            with an interactive drag-and-drop user interface. Join the community
            by signing up!
          </p>
        </div>
        <div v-else>
          <h1 class="mb-5">Hi {{ currentUser.firstName }}!</h1>
        </div>
        <button class="btn" @click="$router.push('/composer')">
          Get Started
        </button>
      </header>
      <div class="col-sm-12 col-md-6 col-xl-4">
        <SignupPanelDashboard v-if="!this.isAuthenticated" />
        <ProjectPanelDashboard v-if="this.isAuthenticated" />
      </div>
    </section>
    <section class="row community-section">
      <header class="col-lg-6 col-xl-4 mb-4">
        <div class="mb-5">
          <h2 class="mb-2">Piquasso Community</h2>
          <p class="subtitle">
            Browse, find and discover new circuits in Piquasso’s rapidly growing
            public repository.
          </p>
        </div>
        <router-link class="pq-community-btn-more" to="/published">
          Discover
          <img
            src="@/assets/images/button-arrows-white.svg"
            alt="Button arrows"
          />
        </router-link>
      </header>
      <RecentlyPublishedPanelDashboard class="col-lg-6 col-xl-5" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SignupPanelDashboard from '@/components/accountmanagement/signup-panel-dashboard'
import ProjectPanelDashboard from '@/components/project/project-panel-dashboard'
import RecentlyPublishedPanelDashboard from '@/components/project/recently-published-panel-dashboard'

export default {
  name: 'Dashboard',
  components: {
    SignupPanelDashboard,
    ProjectPanelDashboard,
    RecentlyPublishedPanelDashboard,
  },
  mounted() {
    document.title = 'Dashboard | Piquasso'
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'currentUser']),
  },
}
</script>

<style scoped>
.dashboard-page > section {
  margin-bottom: 15rem;
}
/* Welcome section */
.welcome-section {
  min-height: 31rem;
  margin-top: 5rem;
  margin-bottom: 8rem !important;
  align-items: center;
}
.welcome-section header h1 {
  color: var(--pq-font-color-shade1);
  font-size: 3.5rem;
  font-weight: 400;
}
.subtitle {
  color: var(--pq-font-color-shade4);
  font-size: 1.1rem;
}
.welcome-section button,
.community-section header button,
.pq-community-btn-more {
  color: #fff;
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 5px;
  background-color: var(--pq-color-red);
}

/* Community */
.community-section header {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.community-section header h2 {
  font-size: 3.25rem;
}
.pq-community-btn-more {
  width: 17.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.pq-community h5 {
  font-size: 1rem;
  color: var(--pq-font-color-shade3);
}
</style>
