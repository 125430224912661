<template>
  <button
    class="project-item d-flex flex-column"
    @click="$router.push({ name: 'composer', params: { projectId } })"
  >
    <div class="project-item-status d-flex flex-row mb-2">
      <span class="project-name">{{ projectName }}</span>
      <button class="more-btn">
        <img src="@/assets/images/icons/more-vertical.png" alt="More" />
      </button>
    </div>
    <div class="project-item-updated-at d-flex align-items-center justify-content-start">
      <img
        class="user-profile-picture mr-2"
        :src="currentUser.avatar"
        alt="Profile picture"
      />
      <span>Updated {{ updatedAt }}</span>
    </div>
  </button>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'project-item',
  props: ['projectId', 'projectName', 'updatedAt'],
  computed: {
    ...mapGetters(['currentUser']),
  },
}
</script>

<style scoped>
.project-item {
  color: var(--pq-font-color-shade2);
  width: 100%;
  padding: 1rem 1rem;
  border: none;
  border-bottom: 1px solid var(--pq-color-border);
  background-color: transparent;
}

.project-item:hover {
  background-color: var(--pq-blue-shade3);
}

.more-btn {
  background-color: transparent;
  border: none;
  display: none;
  max-height: 1.5rem;
}

.more-btn img {
  width: 0.25rem;
}

.project-item:hover .more-btn {
  display: block;
}

.project-item-status {
  width: 100%;
  justify-content: space-between;
}

.project-item-status > img {
  width: 1.15rem;
}

.project-name {
  color: var(--pq-font-color-shade1);
  font-weight: 400;
}

.project-item-updated-at {
  font-size: 0.85rem;
  color: var(--pq-font-color-shade3);
  justify-content: space-between;
}

.user-profile-picture {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
}
</style>
