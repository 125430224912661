<template>
  <ASocialMediaAuthButton
    class="fa-google"
    :icon="['fab', 'google']"
    size="lg"
    name="Google"
    service="google"
  />
</template>

<script>
import ASocialMediaAuthButton from '@/components/accountmanagement/a-socialmedia-auth-button'
export default {
  name: 'google-auth-button',
  components: {
    ASocialMediaAuthButton,
  },
}
</script>

<style scoped>
.fa-google {
  background: #dd4b39;
  color: white;
}
</style>
