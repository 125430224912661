<template>
  <button class="btn fa" @click="userSignIn">
    <font-awesome-icon class="mr-2" :icon="this.icon" :size="this.size" />
    {{ name }}
  </button>
</template>

<script>
import { LOGIN } from '@/store/actions.type'

export default {
  name: 'a-socialmedia-auth-button',
  props: ['icon', 'size', 'name', 'service'],
  methods: {
    userSignIn() {
      window.open(
        `${process.env.VUE_APP_HTTP_URL}/auth/${this.service}`,
        '_blank'
      )
      window.addEventListener('message', this.saveUser)
    },
    saveUser(e) {
      if ('user' in e.data) {
        window.removeEventListener('message', this.saveUser)
        this.$store.dispatch(LOGIN, e.data)
      }
    },
  },
}
</script>

<style scoped>
.fa {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 7rem;
  font-size: 0.8rem;
  border: 0;
  border-radius: 0.5rem;
  padding: 0;
  margin-right: 1rem;
}

.fa:hover {
  opacity: 0.7;
}
</style>
