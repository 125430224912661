<template>
  <div>
    <div class="trending d-flex flex-column overflow-hidden">
      <h5>Recently published</h5>
      <div class="trending-list d-flex flex-column">
        <template v-for="(project, i) in recentlyPublishedProjects">
          <PublicProjectItem
            :key="project.id"
            :project-id="project.id"
            :project-index="i + 1"
            :project-name="project.name"
            :user-name="project.owner.firstName"
            :user-avatar="project.owner.avatar"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PublicProjectItem from '@/components/project/public-project-item'
import { mapGetters } from 'vuex'
import { GET_PUBLISHED_PROJECTS } from '@/store/actions.type'

export default {
  name: 'recently-published-panel-dashboard',
  components: {
    PublicProjectItem,
  },
  data() {
    return {
      recentlyPublishedProjects: [],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  async mounted() {
    this.recentlyPublishedProjects = await this.$store.dispatch(
      GET_PUBLISHED_PROJECTS,
      { start: 0, stop: 5 }
    )
  },
}
</script>

<style scoped>
.trending {
  background-color: var(--pq-blue-shade2);
  border-radius: 1rem;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, .07);
}

.trending h5 {
  padding: 1rem;
}

.trending-list button:last-child {
  border-bottom: none;
}
</style>
