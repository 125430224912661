<template>
  <div class="pq-signup">
    <header class="mb-4">
      <h3>Sign in to Piquasso</h3>
      <p>
        You can sign in with your social media account and gain access to all of
        our cool features
      </p>
    </header>
    <div class="signup-options d-flex text-center justify-content-evenly">
      <GoogleAuthButton />
      <GithubAuthButton />
    </div>
  </div>
</template>

<script>
import GoogleAuthButton from '@/components/accountmanagement/google-auth-button'
import GithubAuthButton from '@/components/accountmanagement/github-auth-button'

export default {
  name: 'signup-panel-dashboard',
  components: {
    GoogleAuthButton,
    GithubAuthButton,
  },
}
</script>

<style scoped>
.pq-signup {
  background-color: var(--pq-blue-shade2);
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 0 15px 5px rgb(0, 0, 0, 0.07);
}

.pq-signup header h3 {
  color: var(--pq-font-color-shade1);
  font-weight: 400;
  font-size: 1.5rem;
}

.pq-signup header p {
  font-size: 0.9rem;
}
</style>
